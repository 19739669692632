@tailwind base;
@tailwind components;
@tailwind utilities;

/* === Color Palette === */
:root {
    --primary-blue: #47B2DE;
    --accent-yellow: #FFC107;
    --dark-navy: #003F63;
    --light-gray: #F4F6F8;
    --muted-gray: #E0E0E0;
    --text-dark: #2D3748;
}

/* === Base Typography === */
body {
    font-family: 'Open Sans', sans-serif;
    background: linear-gradient(to bottom, #E9F3FA, white);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    color: var(--text-dark);
}

/* === Utility Overrides === */
*, *::before, *::after {
    box-sizing: border-box;
}

/* === Custom Elements === */
.btn-primary {
    background: linear-gradient(90deg, var(--primary-blue), #005C89);
    color: white;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 9999px;
    text-decoration: none;
    transition: transform 0.2s ease, background 0.2s ease;
    display: inline-block;
}

.btn-primary:hover {
    background: #00416A;
    transform: scale(1.05);
}

.card {
    background-color: white;
    border: 1px solid var(--muted-gray);
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    color: var(--text-dark);
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* === Section Backgrounds === */
.bg-light-blue {
    background-color: #E9F3FA;
}

/* === Text Utilities === */
.text-primary-blue {
    color: var(--primary-blue);
}